$color-light-yellow: #fbff00;
$color-light-orange: #ff6600;
$color-light-red: #ff0000;
$color-light-limegreen: #73ff00;
$color-light-darkgreen: #00bd00;
$color-light-turquoise: #00d9ff;
$color-light-blue: #0004ff;
$color-light-purple: #c300ff;
$color-light-indigo: #ff13b0;
$color-light-black: #000000;
$color-light-white: #ffffff;
$color-light-grey: #dee2e6;

$color-yellow: #fbff00;
$color-orange: #ff6600;
$color-red: #ff0000;
$color-limegreen: #73ff00;
$color-darkgreen: #00bd00;
$color-turquoise: #00d9ff;
$color-blue: #0004ff;
$color-purple: #c300ff;
$color-indigo: #ff13b0;
$color-black: #000000;
$color-white: #ffffff;
$color-teal: #55a09d;

$primary-color: #c91f27;
$secondary-color: #1a3661;
$accent-color: #f4d2d3;
$hover-color: #781217;

$background-color: #f5f5f5;

$management-color-primary: #0c4946;
$management-color-accent: #168d87;
$management-color-link: #c8fdfb;

$table-row-color-even: #f5f5f5;
$table-row-color-odd: #d1e4e3;

$management-select-button-border: #ccc;
$management-select-button-border-hover: #adadad;
$management-select-button-color-hover: #373a3c;
$management-select-button-background-hover: #e6e6e6;

$management-red-button: #dc3545;
$management-red-button-hover: #801f28;

$management-teal-button: #168d87;
$management-teal-button-hover: #0d4e4b;

$font-family: "OpenSans";
