@import "variables";

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/OpenSans/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

html {
  scroll-behavior: smooth;
  background-color: $background-color;
}

body {
  font-family: "OpenSans", Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  line-height: 2em !important;
  letter-spacing: 0.01em;
  padding: 0;
  margin: 0;
}


.empty-row {
  height: 30px; 
}

// ---------------------------------------------------------COMMON COMPONENTS SECTION--------------------------------------------------------------------------

.font-open-sans {
  font-family: "OpenSans" !important;
}

.visible-border-left {
  border-left: $management-color-primary;
  border-left-width: 10px;
  border-left-style: solid;
}

.transparent-border-left {
  border-left: #ffffff00;
  border-left-width: 10px;
  border-left-style: solid;
}

#parent {
  padding-top: 67px;
}

.panel-title {
  font: 1.9rem "OpenSans";
  font-weight: 800;
  color: #1a3661;
  padding-bottom: 0px;
}

.input-checkbox {
  height: 32px !important;
}

.checkbox-label {
  color: $management-color-primary !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.modal-title {
  font: 1.2rem "OpenSans";
  font-weight: 600;
  color: $management-color-primary !important;
}

.modal-body {
  font: 1rem "OpenSans";
  color: $management-color-primary !important;
}

.white-font {
  color: $color-white;
}

.teal-font {
  color: $management-color-accent;
}

.cursor-pointer {
  cursor: pointer;
}

// ---------------------------------------------------------NAVBAR SECTION--------------------------------------------------------------------------
.navbar {
  z-index: 1000;
  background-color: #c91f27 !important ;
}

.show-navbar {
  opacity: 1;
}

.hide-navbar {
  opacity: 0;
}

.custom-nav-logo {
  opacity: 0.9 !important ;
  @include hover-focus {
    opacity: 1 !important;
    cursor: pointer;
  }
}

.custom-nav-link {
  font-size: 1.5rem;
  font-weight: 800 !important;
  color: #f4d2d3 !important;
  text-align: left !important;
}

.custom-nav-link:hover {
  color: #781217 !important;
  cursor: pointer;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler-icon {
  background-image: url("../resources/home/NavbarBars.svg") !important;
}

// ---------------------------------------------------------SCHOOL SECTION--------------------------------------------------------------------------

.school-anchor {
  position: relative;
  bottom: 67px;
}

.school-container {
  font: 18px "OpenSans";
  font-weight: 600;
  color: #1a3661;
  border-radius: 30px !important;
  background-color: $background-color !important;
  overflow: hidden;
}

.read-more-text {
  cursor: pointer;
}

.read-more-text:hover {
  color: #08101d;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
}

.school-gallery-img {
  border-radius: 30px;
  user-select: none;
}

@media screen and (max-width: 992px) {
  .school-container {
    font: 16px "OpenSans";
    font-weight: 600;
  }
  .panel-title {
    font: 1.4rem "OpenSans";
    font-weight: 800;
    margin-bottom: 0;
    padding-bottom: 8px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
  cursor: grab;
}

.swiper-slide {
  background: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacher-swiper-profile {
  user-select: none;
  padding-top: 20px;
  padding-bottom: 20px;
}

.portrait-image {
  max-height: 200px;
  cursor: pointer;
  filter: brightness(100%);
}

.portrait-image:hover {
  filter: brightness(80%);
}

.portrait-in-card {
  max-height: 200px;
}

.teacher-footer {
  background-color: #ca1922;
  color: $background-color;
  justify-content: center;
  cursor: pointer;
  filter: brightness(100%);
}

.teacher-footer:focus,
.teacher-footer:hover {
  filter: brightness(80%);
}

// ---------------------------------------------------------OFFER SECTION--------------------------------------------------------------------------

.offer-anchor {
  position: relative;
  bottom: 130px;
}

.top-wave {
  margin-top: 2vh;
  aspect-ratio: 960/100;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../resources/home/TopWave.svg");
}

.bottom-wave {
  aspect-ratio: 960/100;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../resources/home/BottomWave.svg");
}

.offer-block {
  background-color: $background-color;
  position: relative;
}

.page-title {
  font: 1.9rem "OpenSans";
  font-weight: 800;
  color: #1a3661;
}

.offer-price-title {
  font: 1.5rem "OpenSans";
  font-weight: 800;
  color: #1a3661;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.tool-tip-trigger {
  cursor: help;
  border-bottom: 3px dotted #1a36618c;
  user-select: none;
}

#tooltip > .tooltip-inner {
  font: 14px "OpenSans";
  background-color: $background-color;
  color: #10223d;
}

.tooltip > .arrow {
  opacity: 0 !important;
}

.offer-price-panel {
  border: none !important;
  border-radius: 30px !important;
  background-color: #c91f2741 !important;
  width: 100%;
  overflow: hidden;
}

.offer-price-text {
  font: 22px "OpenSans";
  font-weight: 700;
  color: #1a3661e8;
}

.offer-accordion {
  position: relative;
}

.offer-accordion-menu {
  z-index: 2;
  position: absolute;
  overflow: hidden;
  height: 585px;
}

.offer-tabs-column {
  border-radius: 30px;
  overflow: hidden;
}

.offer-return-column {
  color: #0e1d33e5;
  cursor: pointer;
  height: 585px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(100%);
}

.offer-return-column:hover {
  filter: brightness(85%);
}

.offer-card {
  font: 18px "OpenSans";
  font-weight: 600;
  color: #0e1d33e5;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 585px;
  width: 100%;
  z-index: 0;
  border-radius: 30px;
  padding-left: 2.5rem;
}

.offer-card::-webkit-scrollbar {
  display: none;
}

.offer-tab-title {
  font: 1.5rem "OpenSans";
  font-weight: 800;
  color: #0e1d33e5;
  user-select: none;
}

.offer-tab {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  height: 65px;
  text-overflow: ellipsis;
  filter: brightness(100%);
}

.offer-tab:hover {
  filter: brightness(85%);
}

.offer-expand {
  width: 100%;
  transition: width 0.4s ease-in-out;
}

.offer-collapse {
  width: 2.5rem;
  transition: width 0.4s ease-in-out;
}

@media screen and (max-width: 992px) {
  .offer-card {
    font: 16px "OpenSans";
    font-weight: 600;
  }
  .offer-price-title {
    font: 1.4rem "OpenSans";
    font-weight: 800;
  }
  .offer-anchor {
    bottom: 100px;
  }
}

@media screen and (max-width: 576px) {
  .offer-anchor {
    bottom: 40px;
  }
}

@media screen and (min-width: 1200px) {
  .offer-price-panel {
    height: 254px;
  }
}

.offer-tab-colour-1 {
  background-color: #f94144;
}

.offer-tab-colour-2 {
  background-color: #f3722c;
}

.offer-tab-colour-3 {
  background-color: #f8961e;
}

.offer-tab-colour-3 {
  background-color: #f9844a;
}

.offer-tab-colour-4 {
  background-color: #f9c74f;
}

.offer-tab-colour-5 {
  background-color: #90be6d;
}

.offer-tab-colour-6 {
  background-color: #44ad8e;
}

.offer-tab-colour-7 {
  background-color: #55a09d;
}

.offer-tab-colour-8 {
  background-color: #5e7f9c;
}

.offer-tab-colour-9 {
  background-color: #2985ac;
}

.offer-card-colour-1 {
  background-color: rgba(249, 65, 68, 0.5);
}

.offer-card-colour-2 {
  background-color: rgba(243, 114, 44, 0.5);
}

.offer-card-colour-3 {
  background-color: rgba(248, 150, 30, 0.5);
}

.offer-card-colour-3 {
  background-color: rgba(249, 132, 74, 0.5);
}

.offer-card-colour-4 {
  background-color: rgba(249, 198, 79, 0.5);
}

.offer-card-colour-5 {
  background-color: rgba(144, 190, 109, 0.5);
}

.offer-card-colour-6 {
  background-color: rgba(68, 173, 141, 0.5);
}

.offer-card-colour-7 {
  background-color: rgba(85, 160, 157, 0.5);
}

.offer-card-colour-8 {
  background-color: rgba(94, 127, 156, 0.5);
}

.offer-card-colour-9 {
  background-color: rgba(41, 133, 172, 0.5);
}

// ---------------------------------------------------------CONTACT SECTION--------------------------------------------------------------------------

.contact-anchor {
  position: relative;
  bottom: 20px;
}

.contact {
  background-color: #252529;
  color: $background-color;
}

.contact-page {
  font: 1.3rem "OpenSans";
  font-weight: 600;
  padding-bottom: 100px;
}

.contact-page-title {
  font: 1.9rem "OpenSans";
  font-weight: 800;
  color: $background-color;
  padding-bottom: 0;
}

.contact-info {
  font: 1.1rem "OpenSans";
  font-weight: 600;
}

.contact-icon {
  margin-right: 10px;
}

.google-maps {
  max-height: 25vh;
  border-radius: 30px;
}

// ---------------------------------------------------------SIGNUP SECTION--------------------------------------------------------------------------
.signup {
  background-color: #252529;
  color: #f5f5f5;

  .form-column-container {
    display: flex;
    justify-content: space-between;
  }

  .form-column {
    flex: 1;
    margin: 0 5px;
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0px !important;
    color: #f5f5f5 !important;
  }

  .radio-container {
    justify-content: center;
    display: flex;
    gap: 3rem;
  }
}

.signup-page {
  font: 1.3rem "OpenSans";
  font-weight: 600;
  padding-bottom: 100px;
}

.signup-page-title {
  font: 1.9rem "OpenSans";
  font-weight: 800;
  color: $background-color;
  padding-bottom: 0;
}

.signup-info {
  font: 1.1rem "OpenSans";
  font-weight: 400;
}

.signup-info-time {
  margin-bottom: 1rem;
}

.signup-info-small {
  font: 0.9rem "OpenSans";
  font-weight: 400;
}

.signup-form-row {
  margin-bottom: 1rem !important;
  .row {
    margin-right: auto;
    margin-left: auto;
  }
}

.svg-icon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  fill: #fff;
}

.tooltip-trigger {
  height: 1em;
  vertical-align: middle;
}

.tooltip-text {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  width: 25vw;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.7s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

// ---------------------------------------------------------QUIZ SECTION--------------------------------------------------------------------------

.quiz-page {
  background-color: $background-color;
  height: 80vh;
}

.quiz-text {
  color: #122542;
}

.quiz-container {
  background-color: $color-white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #122542;
  border-radius: 30px;
  font: 16px "OpenSans";
  font-weight: 600;
}

.quiz-title {
  font: 3rem "OpenSans";
  font-weight: 800;
}

.quiz-button {
  border: none;
  border-radius: 30px;
  font: 3rem "OpenSans";
  font-weight: 800;
  background-color: #c91f27;
  color: $background-color;
}

.quiz-button:hover {
  color: #781217;
}
// ---------------------------------------------------------GALLERY SECTION--------------------------------------------------------------------------

#image-gallery {
  .row {
    display: flex;
    flex-wrap: wrap;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }

  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
}

// ---------------------------------------------------------MANAGE SECTION--------------------------------------------------------------------------

// @media screen and (min-width: 768px) {
//   .custom-modal .modal-dialog {
//     max-width: 800px !important;
//   }
// }

.table-button {
  background-color: #5ebbb6;
  filter: brightness(100%);
}

.table-button:hover {
  filter: brightness(80%);
}
// ---------------------------------------------------------MANAGE COMMON SECTION-------------------------------------------------------------------

.manage-container {
  background-color: $background-color;
  color: $management-color-primary !important;
}

.manage-title {
  font: 1.7rem "OpenSans";
  font-weight: 600;
  color: $management-color-primary !important;
  padding-bottom: 10px;
}

.manage-table-title {
  color: $color-white !important;
  font-weight: 600 !important;
  background-color: $management-color-accent !important;
}

.manage-text {
  color: $management-color-primary !important;
}

.table-row {
  background-color: $table-row-color-even;
}

.table-cell {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-row-alternate {
  background-color: $table-row-color-odd !important;
}

.table-row:hover {
  filter: brightness(80%);
}

.manage-button {
  color: $color-white !important;
  font-weight: 600 !important;
}

.manage-select-button {
  color: $management-color-primary;
  font-size: large;
  padding: 0.375rem 1rem;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid $management-select-button-border;
  margin-left: 16px;
  background-color: transparent;

  &:hover {
    background-color: $management-select-button-background-hover;
    border-color: $management-select-button-border-hover;
    color: $management-select-button-color-hover;
  }
}

.manage-red-button {
  background-color: $management-red-button !important;
  border-color: $management-red-button !important;
}

.manage-red-button:hover {
  background-color: $management-red-button-hover !important;
}

.manage-teal-button {
  background-color: $management-teal-button !important;

  &.selected {
    background-color: $management-color-primary !important;
  }
}

.manage-teal-button:hover {
  background-color: $management-teal-button-hover !important;
}

.manage-icon {
  color: $management-color-primary !important;
}

@media screen and (min-width: 1200px) {
  .spacer-div {
    width: 0;
  }
}

// ---------------------------------------------------------MANAGE NAVBAR SECTION-------------------------------------------------------------------

#nav.manage-custom-nav {
  background-color: $management-color-accent !important ;
}

.manage-custom-nav-logo {
  filter: brightness(90%);
  @include hover-focus {
    filter: brightness(80%);
    cursor: pointer;
  }
}

.manage-custom-nav-link {
  font-size: 1.5rem;
  font-weight: 800 !important;
  color: $management-color-link !important;
  text-align: left !important;
  // Disabled state lightens text
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}

.manage-custom-nav-link:hover {
  color: $management-color-primary !important;
  cursor: pointer;
}

// ---------------------------------------------------------ATTENDANCE REPORT SECTION-------------------------------------------------------------------
.custom-collapse {
  max-height: 0px;
  transition: max-height 0.4s ease-in-out !important;
  overflow: hidden;
}

.custom-collapse.show {
  max-height: 500px !important;
}

.report-calendar-container {
  .selected-day {
    background-color: $table-row-color-odd !important;

    &.start {
      background-color: #4e9cb4 !important;
    }
    &.end {
      background-color: #4eb4af !important;
    }
  }

  .rbc-date-cell {
    a {
      pointer-events: none !important;
    }
  }

  .rbc-selected-cell {
    background-color: transparent;
  }

  .rbc-day-bg {
    border-right: 1px solid #ddd;
  }

  .rbc-off-range {
    opacity: 0;

    height: 0px !important;
    pointer-events: none;
  }

  .rbc-off-range-bg {
    opacity: 0;
    height: 0px !important;
    pointer-events: none;
  }
}

.sortable-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.text-with-icon {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  transition: font-weight 200ms ease;
}

.sortable-column:hover .text-with-icon {
  font-weight: 900;
}

.report-filters {
  .report-checkbox {
    scale: 175%;
  }
}

// ---------------------------------------------------------MANAGE CALENDAR SECTION-------------------------------------------------------------------

.my-calendar {
  & .rbc-toolbar-label {
    color: $management-color-primary;
    font-weight: bold;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  & .rbc-toolbar button {
    color: $management-color-primary;
  }

  & button:focus {
    outline: none !important;
  }

  & .rbc-month-header,
  & .rbc-time-header,
  & .rbc-header {
    background-color: $management-color-accent;
    color: $color-white;
    border-bottom: none;
  }

  & .rbc-current-time-indicator {
    display: none;
  }

  & .rbc-timeslot-group {
    font-weight: 600;
  }

  & a {
    text-align: center;
    text-decoration: none;
    color: $management-color-primary;
    padding-top: 5px;

    &:hover {
      font-weight: bold;
      text-decoration: none;
      color: $management-color-primary;
      border-bottom: 3px solid $management-color-primary;
    }

    &:focus {
      outline: none;
    }
  }

  & .rbc-event {
    text-align: center;
    background-color: $background-color;
    color: $management-color-primary;
    border: 2px solid $management-color-primary !important;
    border-image: none;
    box-sizing: border-box;
    margin-top: 3px;
    padding: 0px;
    max-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .rbc-day-slot .rbc-events-container {
    margin: 0px;
  }

  & .display-block {
    height: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  & .student-name-display-block {
    margin: 0px 2px;
    min-width: 45px;
    line-height: 1.5;
  }

  & .rbc-event-content .display-block > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .rbc-event:focus {
    outline: none;
  }

  & .rbc-event-label {
    display: none;
  }

  & .rbc-selected {
    background-color: $background-color !important;
    padding: 0;
  }

  & .dark-background {
    color: #c8fdfb;
    background-color: $management-color-primary;
  }

  & .light-background {
    border-color: $management-color-primary;
    border-radius: 2px;
    color: $management-color-primary;
    background-color: $background-color;
  }

  & .rbc-allday-cell {
    display: none;
  }

  & .rbc-time-slot {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .rbc-agenda-event-cell {
    padding: 0 !important;
  }
}
