#quiz {
  h1 {
    font-weight: normal;
    font-size: 2.25em;
    letter-spacing: -1px;
    text-align: center;
  }
  header {
    justify-content: space-between;
    align-items: center;
  }

  ul {
    padding: 0;
  }

  p {
    margin: 0;
  }

  span {
    pointer-events: none;
  }

  code {
    font-family: monospace;
    font-size: 0.8em;
    font-weight: 100;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.09);
    padding: 3px 4px;
    margin: 0 2px;
  }

  .description {
    font-size: 1.5em;
    padding-top: 20px;
  }

  .questions {
    width: 85%;
    margin: 35px auto 0;
  }

  .question {
    font-size: 2em;
  }

  .question-answers {
    margin-top: 0.75em;
    padding-left: 1.2em;
  }

  .question-answer {
    list-style-type: lower-alpha;
    cursor: pointer;
    padding: 0.3em;
    margin-bottom: 0.3em;
    border: 5px solid transparent;
  }

  .question-answer span {
    line-height: 1.3;
  }

  .answer {
    font-size: 1em;
  }

  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translate3d(40%, 0, 0);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  }

  .question {
    display: none;
    animation: slide-in 0.4s ease;
  }

  .question:first-child {
    display: block;
  }


  .wrong {
    background: rgba(236, 100, 75, 0.5);
    animation: shake 0.5s cubic-bezier(0.35, 0.05, 0.2, 0.99) both;
  }

  .right {
    background: rgba(135, 211, 124, 0.5);
  }

  @keyframes shake {
    10%,
    90% {
      transform: translateX(-1px);
    }
    20%,
    80% {
      transform: translateX(1px);
    }
    30%,
    50%,
    70% {
      transform: translateX(-2px);
    }
    45%,
    55% {
      transform: translateX(2px);
    }
  }

  @media (min-width: 600px) {
    body {
      font-size: 12px;
    }
  }

  @media (min-width: 900px) {
    body {
      font-size: 14px;
    }

    h1 {
      font-size: 3em;
    }

    .questions {
      width: 75%;
    }

    .question-answer:hover {
      border-color: rgba(0, 0, 0, 0.5);
    }

    .question-answer:focus {
      outline: gray solid 1px;
    }

  }

  @media (min-width: 1400px) {
    body {
      font-size: 16px;
    }
  }

  @media (min-width: 1600px) {
    body {
      overflow: hidden;
    }
  }
}


.praise,
.points {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

.quiz-results {
  &__container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    animation: fade 0.4s ease;

    a {
      position: relative;
      margin-top: 30px;
      border: 3px solid #111;
      background: none;
      cursor: pointer;
      font-size: 0.75em;
      transition: background 0.2s;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background: rgba(255, 255, 255, 0.5);
        top: -2px;
      }
    }
  }

  &__header {
    margin-bottom: 20px;
    font-size: 18px;
  }

  &__question {
    margin-bottom: 20px;
    &-number {
      //text-decoration: underline;
      font-variant-caps: small-caps;
    }
  }

  &__answer {
    list-style-type: lower-alpha;

    &--selected {
      font-weight: bold;

      &.quiz-results__answer--correct {
        background: rgba(135, 211, 124, 0.5);
      }
    }

    &--selected:not(&--correct) {
      background: rgba(236, 100, 75, 0.5);
    }

    &--correct:not(&--selected) {
      border-color: rgba(135, 211, 124, 0.5);
      border-width: 3px;
      border-style: solid;
    }

  }

  .results-total {
    margin-top: 15px;
    font-size: 1.1em;
  }

}
