#homepage-background {
  background: url("../resources/home/NewDesign/Podziemie.svg");
  background-color: #392210;
  background-repeat: repeat-y;
  background-size: contain;
  background-attachment: scroll;
  background-position: top;
  background-origin: border-box;
  width: 100%;
}

#HomepageSVG {
  position: scroll;
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  #HomepageSVG {
    margin-top: 67px;
  }
}

.homepage-link {
  text-decoration: none !important;
  fill: #1A3661;
  cursor: pointer;
}

.homepage-anchor {
  margin-top: -100px;
}